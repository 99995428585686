import type { FabricationOrder } from '~/types/fabricationOrder';
import { provideApolloClient } from '@vue/apollo-composable';
import { FABRICATION_ORDERS_QUERY } from '~/graphql/fabrication_orders';
import dayjs from 'dayjs';

export type EPIType = 'co2' | 'kwh' | 'euro';

export const useEPI = () => {
  // Composables
  const { apolloClient } = useApollo();
  provideApolloClient(apolloClient);
  const { getConsumption } = useConsumption();

  const getEPIType = (): { key: EPIType; label: string }[] => {
    return [
      {
        key: 'kwh',
        label: 'kWh',
      },
      {
        key: 'euro',
        label: '€',
      },
      {
        key: 'co2',
        label: 'CO2',
      },
    ];
  };

  const getNewEPIType = (): { key: string; label: string }[] => {
    return [
      {
        key: 'epi',
        label: 'kWh',
      },
      {
        key: 'cost_by_quantity',
        label: '€',
      },
      {
        key: 'co2',
        label: 'gCO2',
      },
    ];
  };

  const convertConsumptionToEPI = (options: { value: number; type: EPIType; meterId: number }) => {
    // Variables
    const site = siteStore().getSite(globalStore().getSelectedSite[0]);
    const meter = meterStore().getMeter(options.meterId);
    if (!meter) return options.value;
    const energyPrice = pricingStore().getPricingBySiteId(site.id);

    if (options.type === 'co2') {
      return (useCO2().getCo2byKWhByMeterId(options.meterId) * options.value).toFixed(2) + ' gCO2';
    } else if (options.type === 'euro') {
      if (meter.meter_type.energy_type === 'Gas') {
        const prices = energyPrice?.find((item) => item.energy_provider === 'grdf')?.prices;
        if (!prices) return 0 * options.value;
        else {
          const price = Math.max(...prices.map((item) => item.price.value));
          return (price * options.value).toFixed(2) + ' €';
        }
      } else {
        const prices = energyPrice?.find((item) => item.energy_provider === 'enedis')?.prices;
        if (!prices) return 0 * options.value;
        else {
          const price = Math.max(...prices.map((item) => item.price.value));
          return (price * options.value).toFixed(2) + ' €';
        }
      }
    }

    return options.value.toFixed(2) + ' kWh';
  };

  const getConsumptionByOFID = async (options: { meterId: number; ofId: number }) => {
    // Get the fabrication order data
    const result: { data: { fabrication_orders_by_pk: FabricationOrder } } = await apolloClient.query({
      query: FABRICATION_ORDERS_QUERY,
      variables: { id: options.ofId },
    });

    // Get the comsuption by the OF
    const consumptionData = await getConsumption({
      meterId: options.meterId,
      start: dayjs.utc(result.data.fabrication_orders_by_pk.start).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs.utc(result.data.fabrication_orders_by_pk.end).format('YYYY-MM-DDTHH:mm:ss'),
      aggregationLevel: 'minute',
    });

    return {
      consumption: consumptionData,
      fabricationOrder: result.data.fabrication_orders_by_pk,
    };
  };

  return { getEPIType, convertConsumptionToEPI, getNewEPIType, getConsumptionByOFID };
};

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const useCO2 = () => {
  const getCo2byKWhByMeterId = (meterId: number) => {
    const site = globalStore().getSelectedSite;
    const contracts = contractStore().getContractBySiteId(site[0]);

    if (typeof site[0] !== 'number') return 0;
    if (meterStore().getMeter(meterId)?.meter_type.energy_type === 'Gas') {
      const gasContracts = contracts.filter((contract) => contract.energy_type === 'Gas');
      const lastGasContract = gasContracts?.find((contract) => dayjs().isBetween(dayjs(contract.start), dayjs(contract.end)));

      return lastGasContract?.emission_factor ?? 0;
    } else if (meterStore().getMeter(meterId)?.meter_type.energy_type === 'Electricity') {
      const electricityContracts = contracts.filter((contract) => contract.energy_type === 'Electricity');
      const lastElectricityContract = electricityContracts?.find((contract) =>
        dayjs().isBetween(dayjs(contract.start), dayjs(contract.end)),
      );

      return lastElectricityContract?.emission_factor ?? 0;
    }
    return 0;
  };

  return {
    getCo2byKWhByMeterId,
  };
};
